import React, { useEffect, useState } from "react";
import Layout from "./components/common/layout/Layout";
import AuthPage from "./pages/auth/AuthPage";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { RootState } from "./redux/store";
import MainPage from "./pages/main/MainPage";
import FinancePage from "./pages/finance/FinancePage";
import ReceiptsPage from "./pages/receipts/ReceiptsPage";
import SpendReceipts from "./pages/spendReceipts/SpendReceiptsPage";
import ReceiveReceipt from "./pages/receiveReceipts/ReceiveReceipts";
import ReceiveReceiptPreview from "./pages/receiveReceiptPreview/ReceiveReceiptPreview";
import ReceiptPreviewLayout from "./components/common/receiptPreviewLayout/ReceiptPreviewLayout";
import SpendReceiptPreview from "./pages/spendReceiptPreview/SpendReceiptPreview";
import Contract from "./pages/contract/ContractPage";
import BuyingAndSellingContractPage from "./pages/buyingAndSellingContract/BuyingAndSellingContractPage";
import RentalContractPage from "./pages/rentalContract/RentalContractPage";
import ArchiveBuyAndSellPage from "./pages/archiveBuyAndSell/ArchiveBuyAndSellPage";
import ArchiveResultBuyAndSell from "./pages/archiveResultBuyAndSell/ArchiveResultBuyAndSellPage";
import SafeBoxPage from "./pages/safebox/SafeBoxPage";
import DepositPage from "./pages/deposit/DepositPage";
import WithdrawPage from "./pages/withdraw/WithdrawPage";
import ExchangeRatePage from "./pages/exchangeRate/ExchangeRatePage";
import CancellationPage from "./pages/cancellation/CancellationPage";
import ContractCancellationSearchPage from "./pages/contractCancellationSearch/ContractCancellationSearchPage";
import AccountStatementPage from "./pages/accountStatement/AccountStatementPage";
import AccountStatementResultPage from "./pages/accountStatementResult/AccountStatementResultPage";
import ContractCancellationResultPage from "./pages/contractCancellationResult/ContractCancellationResultPage";
import PurchasingContractPreviewPage from "./pages/purchasingContractPreview/PurchasingContractPreviewPage";
import RentalContractPreviewPage from "./pages/rentalContractPreview/RentalContractPreviewPage";
import CommissionReceiveReceiptPage from "./pages/commissionReceiveReceipts/CommissionReceiveReceiptsPage";
import CommissionSpendReceiptPage from "./pages/commissionSpendReceipts/CommissionSpendReceiptsPage";
import InsuranceReceiveReceiptPage from "./pages/insuranceReceiveReceipts/InsuranceReceiveReceiptsPage";
import InsuranceSpendReceiptPage from "./pages/insuranceSpendReceipts/InsuranceSpendReceiptsPage";
import RentReceiveReceiptPage from "./pages/rentReceiveReceipts/RentReceiveReceiptsPage";
import RentalSpendReceiptPage from "./pages/rentalSpendReceipts/RentalSpendReceiptsPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchProfileAsync } from "./redux/profileSlice";
import { ThunkDispatch } from "redux-thunk";
import UnauthorizedPage from "./pages/unauthorized/unauthorizedPage";
import RentArchivePage from "./pages/rentArchive/RentArchivePage";
import RentArchiveResultPage from "./pages/rentArchiveResult/RentArchiveResultPage";

const queryClient = new QueryClient();

const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

function App() {
  const [isMobile, setIsMobile] = useState(false);

  const dispatch: ThunkDispatch<RootState, any, any> = useDispatch();
  const profile = useTypedSelector((state) => state.profile.profile);

  useEffect(() => {
    dispatch(fetchProfileAsync());
  }, [dispatch]);

  useEffect(() => {
    const isMobileDevice = () => {
      const userAgent = navigator.userAgent;
      return /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        userAgent
      );
    };

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1500 && isMobileDevice());
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Show a warning for mobile and iPad users
  if (isMobile) {
    return (
      <div className="bg-gray-500 text-white p-4">
        Warning: This website is best viewed on a desktop.
      </div>
    );
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Routes>
          {/* Use a route for the "/" path */}
          <Route
            path="/"
            element={
              <Layout isAuthPage={true}>
                <AuthPage />
              </Layout>
            }
          />
          {/* Use a route for all other paths */}
          <Route
            path="/*"
            element={
              <Layout isAuthPage={false}>
                <Routes>
                  {/* <Route path="/unauthorized" element={<UnauthorizedPage />} /> */}
                  <Route path="/main" element={<MainPage />} />
                  <Route
                    path="/finance"
                    element={
                        <FinancePage />
                    }
                  />
                  <Route path="/receipts" element={<ReceiptsPage />} />
                  <Route
                    path="/spend-receipt/:id?"
                    element={<SpendReceipts />}
                  />
                  <Route
                    path="/receive-receipt/:id?"
                    element={<ReceiveReceipt />}
                  />
                  <Route
                    path="/commission-receive-receipt/:id?"
                    element={
                      <CommissionReceiveReceiptPage title="Commission" />
                    }
                  />
                  <Route
                    path="/commission-spend-receipt/:id?"
                    element={<CommissionSpendReceiptPage title="Commission" />}
                  />
                  <Route
                    path="/insurance-receive-receipt/:id?"
                    element={
                      <InsuranceReceiveReceiptPage title="دڵنیایی (تأمينات)" />
                    }
                  />
                  <Route
                    path="/insurance-spend-receipt/:id?"
                    element={
                      <InsuranceSpendReceiptPage title="دڵنیایی (تأمينات)" />
                    }
                  />
                  <Route
                    path="/rental-receive-receipt/:id?"
                    element={<RentReceiveReceiptPage title="Rent" />}
                  />
                  <Route
                    path="/rental-spend-receipt/:id?"
                    element={<RentalSpendReceiptPage title="Rent" />}
                  />
                  <Route path="/contracts" element={<Contract />} />
                  <Route
                    path="/purchasing-contract-preview/:id?"
                    element={<PurchasingContractPreviewPage />}
                  />
                  <Route
                    path="/rental-contract-preview/:id?"
                    element={<RentalContractPreviewPage />}
                  />
                  <Route
                    path="/rental-contracts/:id?"
                    element={<RentalContractPage />}
                  />
                  <Route
                    path="/archive-buy-and-sell"
                    element={<ArchiveBuyAndSellPage />}
                  />
                  <Route
                    path="/archive-result-buy-and-sell"
                    element={<ArchiveResultBuyAndSell />}
                  />
                  <Route
                    path="/archive-result-buy-and-sell"
                    element={
                      profile?.role === "founder" ||
                      profile?.role === "admin" ||
                      profile?.role === "finance" ? (
                        <ArchiveResultBuyAndSell />
                      ) : (
                        <UnauthorizedPage />
                      )
                    }
                  />

                  <Route path="/rent-archive" element={<RentArchivePage />} />
                  <Route
                    path="/rent-archive-result"
                    element={<RentArchiveResultPage />}
                  />
                  {profile?.role === "founder" && (
                    <Route path="/safe-box" element={<SafeBoxPage />} />
                  )}
                  {profile?.role === "founder" && (
                    <Route path="/deposit" element={<DepositPage />} />
                  )}
                  {profile?.role === "founder" && (
                    <Route path="/withdraw" element={<WithdrawPage />} />
                  )}
                  {profile?.role === "founder" && (
                    <Route
                      path="/exchange-rate"
                      element={<ExchangeRatePage />}
                    />
                  )}
                  {profile?.role === "founder" && (
                    <Route
                      path="/cancellation"
                      element={<CancellationPage />}
                    />
                  )}
                  {profile?.role === "founder" && (
                    <Route
                      path="/contract-cancellation-search"
                      element={<ContractCancellationSearchPage />}
                    />
                  )}
                  {profile?.role === "founder" && (
                    <Route
                      path="/contract-cancellation-result"
                      element={<ContractCancellationResultPage />}
                    />
                  )}
                  <Route
                    path="/account-statement"
                    element={<AccountStatementPage />}
                  />
                  {/* <Route
                    path="/face-verifivation"
                    element={
                      profile?.role === "founder" || profile?.role === "admin" ? (
                        <FaceVerification onSuccess={() => {}} />
                      ) : (
                        <UnauthorizedPage />
                      )
                    }
                  /> */}
                  <Route
                    path="/account-statement-result"
                    element={<AccountStatementResultPage />}
                  />
                  <Route
                    path="/buying-and-selling-contract/:id?"
                    element={<BuyingAndSellingContractPage />}
                  />
                </Routes>
              </Layout>
            }
          />
          <Route
            path="/receive-receipt-preview"
            element={
              <ReceiptPreviewLayout>
                <ReceiveReceiptPreview />
              </ReceiptPreviewLayout>
            }
          />
          <Route
            path="/spend-receipt-preview"
            element={
              <ReceiptPreviewLayout>
                <SpendReceiptPreview />
              </ReceiptPreviewLayout>
            }
          />
        </Routes>
      </Router>
      <ToastContainer />
    </QueryClientProvider>
  );
}

export default App;
